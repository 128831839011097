@use "react-widgets/scss/styles.scss" with (
  $components: (
    'Multiselect',
  ),
  $list-selected-bg: transparent,
  $list-selected-color: inherit
);

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.rw-list-option {
  display: grid;
  grid: 1fr 1fr / repeat(2, min-content) 1fr;
  grid-template-areas:
  "check figure name"
  "check figure count";
  column-gap: 18px;
  align-items: center;
  .checked, .unchecked { grid-area: check }
  img { grid-area: figure; }
  p { grid-area: name; margin: 0 }
  p + p { grid-area: count; }
  .checked { display: none; }
}

.rw-state-selected {
  .checked {
    display: inline;
  }
  .unchecked { display: none; }
}
