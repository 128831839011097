@keyframes react-widgets-autofill-start {
  from {
  }

  to {
  }
}

@keyframes react-widgets-autofill-cancel {
  from {
  }

  to {
  }
}

.rw-btn {
  color: inherit;
  box-shadow: none;
  font: inherit;
  line-height: inherit;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background: padding-box padding-box;
  border: none;
  outline: 0;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  display: inline-flex;
}

.rw-btn:focus {
  outline: none;
}

.rw-btn:disabled, fieldset[disabled] .rw-btn, .rw-state-disabled .rw-btn, .rw-state-readonly .rw-btn {
  cursor: inherit;
}

fieldset[disabled] .rw-btn {
  pointer-events: none;
}

.rw-picker-caret, .rw-picker-btn {
  color: #212529;
}

.rw-picker-btn:hover {
  background-color: #e9ecef;
}

.rw-picker-btn:active {
  background-color: #dee2e6;
}

.rw-picker-btn:disabled, fieldset[disabled] .rw-picker-btn {
  background-color: #e9ecef;
}

.rw-input-addon, .rw-input-addon.rw-picker-btn {
  border-left: 1px solid #ccc;
}

[dir="rtl"] .rw-input-addon, [dir="rtl"] .rw-input-addon.rw-picker-btn {
  border-left: none;
  border-right: 1px solid #ccc;
}

.rw-sr {
  clip: rect(0, 0, 0, 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.rw-widget {
  color: #212529;
  border: none;
  outline: none;
  font-family: inherit;
  font-size: 1em;
  position: relative;
}

.rw-widget, .rw-widget *, .rw-widget:after, .rw-widget:before, .rw-widget :after, .rw-widget :before {
  box-sizing: border-box;
}

.rw-state-readonly, .rw-state-disabled, fieldset[disabled] .rw-widget {
  cursor: not-allowed;
}

.rw-widget-picker {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  grid-template: 1fr / 1fr 1.9em;
  width: 100%;
  min-height: 38px;
  display: grid;
  overflow: hidden;
}

.rw-widget-picker.rw-hide-caret {
  grid-template-columns: 1fr;
}

.rw-state-focus .rw-widget-picker {
  border-color: #80bdff;
  transition: box-shadow .15s ease-in-out;
  box-shadow: 0 0 0 3px #007bff40;
}

.rw-state-focus .rw-widget-picker.rw-widget-input {
  box-shadow: 0 0 0 3px #007bff40, inset 0 1px 1px #00000013;
}

.rw-input {
  color: inherit;
  box-shadow: none;
  font: inherit;
  line-height: inherit;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: none;
  border: none;
  outline: 0;
  margin: 0;
  padding: 0 .857em;
}

.rw-input:disabled {
  opacity: 1;
}

.rw-input[type="text"]::-ms-clear {
  display: none;
}

.rw-input:disabled, .rw-input:read-only {
  cursor: inherit;
}

.rw-widget-input {
  color: #495057;
  width: inherit;
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: inset 0 1px 1px #00000013;
}

.rw-widget-input.rw-state-disabled, .rw-state-disabled .rw-widget-input, fieldset[disabled] .rw-widget-input {
  background-color: #e9ecef;
}

.rw-placeholder, .rw-input::placeholder, .rw-state-disabled .rw-placeholder, .rw-state-disabled .rw-input::placeholder {
  color: #999;
}

.rw-detect-autofill {
  color: inherit;
  box-shadow: none;
  font: inherit;
  line-height: inherit;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: none;
  border: none;
  outline: 0;
  margin: 0;
  padding: 0;
}

.rw-detect-autofill:disabled {
  opacity: 1;
}

.rw-detect-autofill:-webkit-autofill {
  animation-name: react-widgets-autofill-start;
  animation-duration: .01ms;
}

.rw-detect-autofill:not(:autofill) {
  animation-name: react-widgets-autofill-cancel;
  animation-duration: .01ms;
}

:-webkit-any(.rw-webkit-autofill .rw-widget-container, .rw-input:-webkit-autofill) {
  color: #000 !important;
  background-color: #e8f0fe !important;
  background-image: none !important;
}

:is(.rw-webkit-autofill .rw-widget-container, .rw-input:autofill) {
  color: #000 !important;
  background-color: #e8f0fe !important;
  background-image: none !important;
}

.rw-list {
  padding-top: .5em;
  padding-bottom: .5em;
  font-size: 1em;
  overflow: visible auto;
}

.rw-list:focus {
  outline: none;
}

.rw-list-option, .rw-list-option-create {
  -webkit-user-select: none;
  user-select: none;
  color: #212529;
  cursor: pointer;
  background-clip: padding-box;
  border: 1px solid #0000;
}

.rw-list-option:hover, .rw-list-option-create:hover, [data-intent="keyboard"] .rw-list-option.rw-state-focus:not(.rw-state-selected), [data-intent="keyboard"] .rw-state-focus.rw-list-option-create:not(.rw-state-selected) {
  background-color: #e9ecef;
  border-color: #e9ecef;
}

.rw-list-option.rw-state-selected, .rw-state-selected.rw-list-option-create {
  color: inherit;
  background-color: #0000;
  border-color: #0000;
}

.rw-list-option.rw-state-disabled, .rw-state-disabled.rw-list-option-create {
  cursor: not-allowed;
  color: #dee2e6;
  background-color: #0000;
  border-color: #0000;
}

.rw-list-empty, .rw-list-option, .rw-list-option-create, .rw-list-optgroup {
  outline: none;
  padding: .25em 1.5em;
}

.rw-list-empty {
  text-align: center;
  color: #ced4da;
}

.rw-list-optgroup {
  padding-top: 7px;
  font-weight: bold;
}

.rw-list-option-create {
  padding: .25em 1.5em;
  font-size: 1em;
  display: block;
}

.rw-popup-container {
  z-index: 1005;
  padding: 0 1em;
  position: absolute;
  top: 100%;
  left: -1em;
  right: -1em;
}

.rw-popup-container.rw-dropup {
  top: auto;
  bottom: 100%;
}

.rw-state-focus .rw-popup-container {
  z-index: 1006;
}

.rw-slide-transition {
  margin-bottom: 1em;
}

.rw-dropup > .rw-slide-transition {
  margin-top: 1em;
  margin-bottom: 0;
}

.rw-popup {
  -webkit-overflow-scrolling: touch;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 2px;
  overflow: auto;
  box-shadow: 0 .5em 1em #0000002d;
}

.rw-popup .rw-list {
  max-height: 250px;
}

.rw-dropup .rw-popup {
  margin-top: 0;
  margin-bottom: 2px;
  box-shadow: 0 .5em 1em #0000002d;
}

.rw-slide-transition {
  transition: transform .13s, opacity .1s;
}

.rw-slide-transition-entering {
  overflow: hidden;
}

.rw-slide-transition-entering .rw-slide-transition {
  opacity: 1;
  transition-timing-function: ease-out;
  transform: translateY(0);
}

.rw-slide-transition-exiting .rw-slide-transition {
  transition-timing-function: ease-in;
}

.rw-slide-transition-exiting, .rw-slide-transition-exited {
  overflow: hidden;
}

.rw-slide-transition-exiting .rw-slide-transition, .rw-slide-transition-exited .rw-slide-transition {
  opacity: 0;
  transform: translateY(-10%);
}

.rw-slide-transition-exiting.rw-dropup .rw-slide-transition, .rw-slide-transition-exited.rw-dropup .rw-slide-transition {
  opacity: 0;
  transform: translateY(10%);
}

.rw-slide-transition-exited {
  display: none;
}

.rw-multiselect-input {
  color: inherit;
  box-shadow: none;
  font: inherit;
  line-height: inherit;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: none;
  border: none;
  outline: 0;
  max-width: 100%;
  height: 36px;
  margin: 0;
  padding: 0 .857em;
}

.rw-multiselect-input:disabled {
  opacity: 1;
}

.rw-multiselect-input:disabled, .rw-multiselect-input:read-only {
  cursor: inherit;
}

.rw-multiselect-taglist {
  cursor: text;
  outline: none;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.rw-state-disabled .rw-multiselect-taglist {
  cursor: unset;
}

.rw-multiselect-tag {
  cursor: default;
  background-color: #dee2e6;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  align-items: center;
  max-width: 100%;
  min-height: 29.26px;
  margin-top: 3.37px;
  margin-left: 3.37px;
  display: inline-flex;
  overflow: hidden;
}

.rw-multiselect-tag:focus {
  outline: none;
}

[dir="rtl"] .rw-multiselect-tag {
  margin-left: 0;
  margin-right: 3.37px;
  padding: 0 .35em;
}

.rw-multiselect-tag.rw-state-focus {
  z-index: 1;
  transition: box-shadow .15s ease-in-out;
  box-shadow: 0 0 0 3px #007bff40;
}

.rw-multiselect-tag.rw-state-disabled, fieldset[disabled] .rw-multiselect-tag {
  opacity: .65;
}

.rw-multiselect-tag-label {
  padding: 0 .35em;
}

.rw-multiselect-tag-btn {
  color: inherit;
  box-shadow: none;
  font: inherit;
  line-height: inherit;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background: none;
  border: none;
  outline: 0;
  align-self: stretch;
  margin: 0;
  padding: 0 .35em;
}

.rw-multiselect-tag-btn:focus {
  outline: none;
}

.rw-multiselect-tag-btn:disabled {
  cursor: inherit;
}

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
}

.rw-list-option {
  grid-template-rows: 1fr 1fr;
  grid-template-columns: repeat(2, min-content) 1fr;
  grid-template-areas: "check figure name"
                       "check figure count";
  grid-auto-rows: auto;
  grid-auto-columns: auto;
  grid-auto-flow: row;
  align-items: center;
  column-gap: 18px;
  display: grid;
}

.rw-list-option .checked, .rw-list-option .unchecked {
  grid-area: check;
}

.rw-list-option img {
  grid-area: figure;
}

.rw-list-option p {
  grid-area: name;
  margin: 0;
}

.rw-list-option p + p {
  grid-area: count;
}

.rw-list-option .checked {
  display: none;
}

.rw-state-selected .checked {
  display: inline;
}

.rw-state-selected .unchecked {
  display: none;
}
/*# sourceMappingURL=index.27aaa886.css.map */
